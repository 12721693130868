import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { StaticImage } from 'gatsby-plugin-image';

import config from '../config/config.json';

const formProps = (name) => {
    return {
        fullWidth: true, name: name,
        label: name.charAt(0).toUpperCase().concat(name.slice(1)),
        variant: 'outlined', color: 'info'
    }
};

const getEncodedFormData = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
};

const formStatus = Object.freeze({
    READY: 0,
    LOADING: 1,
    SUCCESS: 2,
    ERROR: 3
});

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState(formStatus.READY);

    const resetForm = () => {
        setName('');
        setEmail('');
        setEmailValid(true);
        setMessage('');
    }

    function handleSubmit(e) {
        setStatus(formStatus.LOADING);

        const content = {
            name: name, email: email, message: message
        };

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: getEncodedFormData({ "form-name": "contact", ...content })
        }).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setStatus(formStatus.SUCCESS);
                resetForm();
            } else {
                setStatus(formStatus.ERROR);
            }
        }).catch(error => {
            console.error(error);
            setStatus(formStatus.ERROR);
        });
        e.preventDefault();
    };

    useEffect(() => {
        if (email === '') {
            setEmailValid(true)
        } else {
            const regex = /^\S+@\S+$/;
            setEmailValid(regex.test(email));
        }
    }, [email]);

    const theme = useTheme();
    const styles = {
        personalImageSection: {
            paddingTop: 2,
            paddingBottom: 2,
            display: 'flex',
            alignItems: 'center',
            columnGap: 2
        },
        avatarPicture: {
            borderRadius: '50%',
            height: theme.spacing(12),
            width: theme.spacing(12)
        },
        avatarInitials: {
            backgroundColor: (theme) => theme.palette.primary.light,
            height: theme.spacing(12),
            width: theme.spacing(12)
        }
    }

    const getMessage = () => {
        if (status === formStatus.SUCCESS || status === formStatus.ERROR) {
            return (
                <Grid item>
                    <Typography>
                        {status === formStatus.SUCCESS
                            ? 'Thank you for getting in touch!'
                            : 'Something went wrong. Please try again later.'
                        }
                    </Typography>
                </Grid>
            );
        }
    };

    const form = (
        <form
            name="contact" onSubmit={handleSubmit}
            data-netlify="true" netlify-honeypot="bot-field"
        >
            <input type="hidden" name="form-name" value="contact" />
            <input hidden type="text" name="bot-field" />
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                    <TextField
                        {...formProps('name')} value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        {...formProps('email')}
                        value={email} error={!emailValid}
                        onChange={e => setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...formProps('message')}
                        multiline rows={4} value={message}
                        onChange={e => setMessage(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <LoadingButton
                        variant="contained" color="primary"
                        type="submit" disabled={!emailValid || message === ''}
                        loading={status === formStatus.LOADING}
                    >
                        Submit
                    </LoadingButton>
                </Grid>
                {getMessage()}
            </Grid>
        </form>
    );

    const getPersonalImage = () => {
        if (config.showPicture) {
            return (
                <Box sx={styles.personalImageSection}>
                    <StaticImage
                        src="../images/jv-headshot-400x400.jpg" alt="Photo of Jack Visser"
                        style={styles.avatarPicture}
                    />
                    <Typography variant="subtitle1" component="span" itemProp="name">
                        Jack Visser
                    </Typography>
                </Box>
            );
        } else {
            return <p/>;
        }
    };

    return (
        <Grid
            container direction="column" spacing={2}
            id="contact" style={{ scrollMarginTop: '64px' }}
        >
            <Grid item>
                <Typography variant="h6" component="h2">
                    Contact
                </Typography>
            </Grid>
            <Grid item>
                <Typography>
                    Feel free to reach out to me at any time.
                </Typography>
            </Grid>
            <Grid item>
                {getPersonalImage()}
            </Grid>
            <Grid item>
                {form}
            </Grid>
        </Grid>
    );
};

export default Contact;
