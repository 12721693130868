import React from 'react';

import Seo from '../components/seo';
import Layout from '../components/layout';
import Contact from '../components/contact';
import Hero from '../components/hero';
import About from '../components/about';
import Section from '../components/section';

const IndexPage = () => {
    return (
        <Layout>
            <Seo title="SDET" />
            <Section altBackground>
                <Hero />
            </Section>
            <Section>
                <About />
            </Section>
            <Section altBackground>
                <Contact />
            </Section>
        </Layout>
    );
};

export default IndexPage;
