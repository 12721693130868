import React from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

import config from '../config/config.json';
import heroContent from '../content/hero.json';

const links = [
    {
        label: `LinkedIn`, href: `https://www.linkedin.com/in/jack-visser/`,
        icon: <LinkedInIcon />, shouldDisplay: config.displayLinkedinButton
    },
    {
        label: `GitHub`, href: `https://github.com/jackv545`,
        icon: <GitHubIcon />, shouldDisplay: config.displayGitHubButton
    }
]

const Hero = () => {
    const styles = {
        root: {
            backgroundColor: (theme) => theme.palette.background.paper,
            paddingTop: (theme) => theme.spacing(12),
            paddingBottom: (theme) => theme.spacing(12)
        },
        position: {
            fontWeight: 'bold'
        },
        location: {
            fontWeight: 'bold',
            color: (theme) => theme.palette.text.secondary
        }
    };

    console.log(links)

    const socialMediaLinks = links.filter((link) => link.shouldDisplay)
        .map((link) => (
            <Grid item key={link.label}>
                <Chip
                    clickable icon={link.icon} label={link.label}
                    title={`${link.label} profile - opens in a new tab`}
                    component="a" href={link.href} target="_blank" rel="noopener"
                />
            </Grid>
        ));

    return (
        <Grid container direction="column" spacing={2} sx={styles.root}>
            <Grid item>
                <Typography variant="h4" component="h1" sx={styles.position}>
                    Jack Visser
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="subtitle1" component="h2" sx={styles.position}>
                    {heroContent.position}
                </Typography>
                <Typography variant="body2" component="p" sx={styles.location}>
                    {heroContent.location}
                </Typography>
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    {socialMediaLinks}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Hero;
