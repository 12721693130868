import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import aboutContent from '../content/about.json';

const About = () => {
    const styles = {
        root: {
            scrollMarginTop: (theme) => theme.spacing(8)
        },
        article: {
            maxWidth: {
                xs: '100%',
                sm: '100%',
                md: '65%'
            }
        }
    };

    return (
        <Grid container direction="column" spacing={2} sx={styles.root} id="about">
            <Grid item>
                <Typography variant="h6" component="h2">
                    About
                </Typography>
            </Grid>
            <Grid item>
                <Box sx={styles.article}>
                    {(aboutContent.map((paragraph, i) => (
                        <Typography paragraph key={`p-${i}`}>
                            {paragraph}
                        </Typography>
                    )))}
                </Box>
            </Grid>
        </Grid>
    );
};

export default About;
