import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, useTheme } from '@mui/material';

const Section = ({ altBackground, children }) => {
    const theme = useTheme();
    const color = altBackground ? theme.palette.background.paper : theme.palette.background.default
    return (
        <Box sx={{ backgroundColor: color, paddingTop: 4, paddingBottom: 4 }}>
            <Container maxWidth="lg">
                {children}
            </Container>
        </Box>
    );
}

Section.propTypes = {
    altBackground: PropTypes.bool,
    children: PropTypes.node.isRequired
};

export default Section;
